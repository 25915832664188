import React, { useState, useEffect } from "react";
import { debounce } from 'lodash';
import "./App.scss";

import MovieSlider from "./components/MovieSlider";
import MovieForm from "./components/MovieForm";
import Twemoji from "./components/Twemoji";
// import { response } from "express";

const DUMMY_MOVIES = [
  {
    key: Math.random(),
    image: "https://miro.medium.com/max/1013/1*Y1YsOwrkJkcxs65B8AEMQw.jpeg",
    title: "Get Out",
    rating: "4",
    genre: "Horror, Mistery, Thriller",
    director: "Jordan Peele",
    isWatched: true,
    year: "2017",
  },
  {
    key: Math.random(),
    image:
      "https://upload.wikimedia.org/wikipedia/en/7/7a/Harry_Potter_and_the_Philosopher%27s_Stone_banner.jpg",
    title: "Harry Potter and the Sorcerer's stone",
    rating: "4",
    genre: "Adventure, Fantasy, Family",
    director: "Chris Columbus",
    isWatched: false,
    year: "2001",
  },
  {
    key: Math.random(),
    image:
      "https://i2.wp.com/movie-screencaps.com/wp-content/uploads/2020/04/4k-hp3-movie-screencaps.com--scaled.jpg?fit=1024%2C427&ssl=1",
    title: "Harry Potter and the Prisoner of Azkaban",
    rating: "5",
    genre: "Adventure, Fantasy, Family",
    director: "Chris Columbus",
    isWatched: false,
    year: "2004",
  },
  {
    key: Math.random(),
    image:
      "https://upload.wikimedia.org/wikipedia/en/e/e7/Harry_Potter_and_the_Goblet_of_Fire_Soundtrack.jpg",
    title: "Harry Potter and the Goblet of Fire",
    rating: "3",
    genre: "Adventure, Fantasy, Family",
    director: "Chris Columbus",
    isWatched: false,
    year: "2018",
  },
];

// const userData = fetch('https://randomuser.me/api/?results=5')
// .then(response => response.json())
// .then(data => console.log(data))

function App() {
  const [movies, setMovies] = useState(DUMMY_MOVIES);

  let newMovie = {
    key: Math.random(),
    image: "https://miro.medium.com/max/1013/1*Y1YsOwrkJkcxs65B8AEMQw.jpeg",
    title: "Get Out",
    rating: "4",
    genre: "Horror, Mistery, Thriller",
    director: "Jordan Peele",
    isWatched: true,
    year: "2017",
  };

  const onClickHandler = () => {
    setMovies((prevState) => {
      return [newMovie, ...prevState];
    });
    console.log(movies);
  };

  const onDeleteHandler = (key) => {
    setMovies((prevState) => {
      return prevState.filter((movie) => movie.key !== key);
    });
    console.log(movies);
    console.log(key);
  };

  // const onClickAddMovieHandler = () => {
  //   console.log("Clicked add movie button");
  //   setIsAddingMovie(true);
  // };

  return (
    <div className="base">
      <div className="bg"></div>
      {/* fetchedData={randomData} */}
      <MovieForm />
      {/* <div className="add-movie" onClick={onClickAddMovieHandler}>
        ➕
      </div> */}
      {/* {isAddingMovie && addMovieForm} */}
      <h1>Watchlist 🎥</h1>
      <h4 className="categoryTitle">Watched <Twemoji emoji="✅" /></h4>
      <MovieSlider category='watched' movieList={movies} onMovieDelete={onDeleteHandler} />
      <h4 className="categoryTitle">Watchlist <Twemoji emoji="👀" /></h4>
      <MovieSlider category='to-watch' movieList={movies} onMovieDelete={onDeleteHandler} />
      <h4 className="categoryTitle">Favorites <Twemoji emoji="⭐" /></h4>
      <MovieSlider category='favorites' movieList={movies} onMovieDelete={onDeleteHandler} />
      {/* <button onClick={onClickHandler}>Add new movie</button> */}
    </div>
  );
}

export default App;
