import React, { useState } from 'react';

import MovieCard from './MovieCard';

const MovieSlider = (props) => {
    let movieList;

    switch (props.category) {
        case 'watched':
            movieList = props.movieList.filter(movie => movie.isWatched);
            break;
        case 'to-watch':
            movieList = props.movieList.filter(movie => !movie.isWatched);
            break;
        case 'favorites':
            movieList = props.movieList.filter(movie => movie.rating === '5');
            break;
        default:
            break;
    }

    return (
        <div className="slider-row">
            <MovieCard movieList={movieList} onMovieDelete={props.onMovieDelete} category={props.category} />
        </div>
    )
}

export default MovieSlider;