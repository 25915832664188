import React, { useState, useEffect } from 'react';
import MovieFormItem from './MovieFormItem';
import './MovieForm.scss'

const MovieForm = () => {
    const [isAddingMovie, setIsAddingMovie] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [searchResults, setSearchResults] = useState(['']);
    const [searchList, setSearchList] = useState(null);
    const [inputValue, setInputValue] = useState('');

    let currentInput = '';

    useEffect(() => {
        fetch(`https://www.omdbapi.com/?apikey=4da9fe34&type=movie&s=${inputValue}`)
            .then(response => response.json())
            .then(data => {
                setSearchResults(prevState => prevState = data.Search)
            })
    }, [inputValue]);

    const searchInputHandler = (e) => {
        if (e.key === 'Enter' && inputValue.length > 0) {
            // console.log("Enter was pressed")
            // console.log(inputValue);
            if (searchResults) {
                filterSearch(searchResults, inputValue);
                setIsAddingMovie(prevState => prevState = true)
                console.log("Searched: " + inputValue)
            }
            else {
                setErrorMessage(true);
            }

        }
        else {
            currentInput = e.target.value;
            setInputValue(prevState => prevState = currentInput);
            setIsAddingMovie(prevState => prevState = false)
            setErrorMessage(false);
        }
        // console.log(currentInput)
    }

    const fetchMovieByID = async (movieID) => {
        return fetch(`https://www.omdbapi.com/?apikey=4da9fe34&type=movie&i=${movieID}`)
            .then(response => response.json())
            .then(data => { return data })
    }

    const selectMovieHandler = async (e) => {
        // console.log(e.target.getAttribute("data-id"))
        let selectedMovie = e.target.getAttribute("data-id")
        let fetchedMovie = await fetchMovieByID(selectedMovie);
        console.log(fetchedMovie)
    }

    const filterSearch = (searches, query, numberOfResults) => {
        let list = searches.filter((item) => (item.Title.toLowerCase()).includes(query.toLowerCase()));
        // console.log(list)
        setSearchList(prevState => prevState = list);
    }

    const sortByImdbRanking = (e) => {

    }

    const onBlurHandler = (e) => {
        console.log(e)
        setIsAddingMovie(false)
    } 

    return (
        <div className="add-movie-form">
            <form autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                <label className="legend">Movie name: (Press enter to search)</label>
                <br />
                <input type="text" id="mname" name="mname" onKeyUp={searchInputHandler} placeholder="Ex: Harry potter" onBlur={onBlurHandler}/>
                <br />
                {isAddingMovie &&
                        <ul className="results-list">
                            {searchList.map(item => (
                                <MovieFormItem key={item.imdbID}
                                    id={item.imdbID}
                                    poster={item.Poster}
                                    title={item.Title}
                                    year={item.Year}
                                    onClick={selectMovieHandler} />
                            ))}
                        </ul>}
                {errorMessage && <p className="form-error">No results :( Please try again.</p>}
            </form>
        </div>
    )
}

export default MovieForm;