import React, { useState } from 'react';



const MovieFormItem = (props) => {

    return (
        <li className="search-result" data-id={props.id} onClick={props.onClick}>
            <div className="img">
                <img src={props.poster}></img>
            </div>
            <p>{props.title} ({props.year})</p>
        </li>
    )
}

export default MovieFormItem;