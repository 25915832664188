import React, { useState } from 'react';

import './MovieCard.scss'

const MovieCard = (props) => {

    const watchedStatus = (status) => {
        if (status) {
            return (
                <div className="status-watched">
                    <p>Watched</p>
                </div>
            )
        }
        else if (!status) {
            return (
                <div className="status-notWatched">
                    <p>To Watch</p>
                </div>
            )
        }
    }

    const createRating = (rating) => {
        let stars = [];
        let star = <span>⭐</span>
        for (let i = 0; i < rating; i++) {
            stars.push(star);
        }

        return stars;
    }

    let movies = props.movieList.map((movie) => (
        <div className="slider-item" key={movie.key} style={{ backgroundImage: `url(${movie.image})` }}>
            <div className="slider-item-content">
                <p className="title">{movie.title} <span className="movie-year">({movie.year})</span></p>
                <div className="rating">{createRating(movie.rating)}</div>
                <div className="legends">
                    <p className="genre legend"><strong>Genre: </strong>{movie.genre}</p>
                    <p className="director legend"><strong>Director: </strong>{movie.director}</p>
                </div>
                {watchedStatus(movie.isWatched)}
                <button className="delete-movie" onClick={() => props.onMovieDelete(movie.key)}>🗑️</button>
            </div>
        </div>
    ))

    return movies;
}

export default MovieCard;